import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/sass/main.scss';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
      isEN: false,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);

    if (window.location.pathname.indexOf("/en") !== -1) {
      this.setState({isEN: true})
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children } = this.props;
    const { isPreloaded, isEN } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata['title'+(isEN ? 'EN' : '')]}
              meta={[
                { name: 'description', content: data.site.siteMetadata['description'+(isEN ? 'EN' : '')] },
                { name: 'keywords', content: 'RevCertCard,RevtelTech,Revtel,忻旅科技,智能加密卡,冷錢包,REV CERT CARD, Rev Cert Card,NFC,React Native,react-native-nfc-manager,react-native-nfc-rewriter,' },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <div
              className={isPreloaded ? ' main-body is-preload' : ' main-body'}
            >
              <div id="page-wrapper">{children}</div>
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
